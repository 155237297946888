function transformTaskToUpdateModel(task) {
  return {
    id: task.id,
    title: {
      en: task.title.en,
      bg: task.title.bg
    },
    notes: {
      en: task.notes.en,
      bg: task.notes.bg
    },
    gold: task.gold,
    type: task.type,
    tags: task.tags.map(tag => tag.id)
  };
}

export default {
  transformTaskToUpdateModel
};
