<template>
  <v-container fluid>
    <v-card class="border-top-blue">
      <v-card-text>
        <tasks-list></tasks-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import TasksList from '../components/TasksList';
import { GET_TAGS } from '../../tags/store/types';

export default {
  name: 'tasks-page',
  components: {
    TasksList
  },
  created() {
    this.$store.dispatch(GET_TAGS);
  }
};
</script>
