<template>
  <v-card>
    <v-card-title>
      <span class="headline font-weight-light" v-if="task.id">
        {{ $t('tasks.editTask') }}
      </span>
      <span class="headline font-weight-light" v-else>
        {{ $t('tasks.createTask') }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="mt-5">
      <v-form ref="manageTaskForm">
        <h3 class="pb-1">{{ $t('common.title') }}</h3>
        <v-text-field
          solo
          v-model="task.title.en"
          :rules="[rules.required]"
          :label="$t('languages.en')"
          class="pl-3"
        ></v-text-field>
        <v-text-field
          solo
          v-model="task.title.bg"
          :rules="[rules.required]"
          :label="$t('languages.bg')"
          class="pl-3"
        ></v-text-field>
        <h3 class="pb-1">{{ $t('common.notes') }}</h3>
        <v-textarea
          solo
          v-model="task.notes.en"
          :label="$t('languages.en')"
          class="pl-3"
        ></v-textarea>
        <v-textarea
          solo
          v-model="task.notes.bg"
          :label="$t('languages.bg')"
          class="pl-3"
        ></v-textarea>
        <v-select
          solo
          :items="gold"
          :label="$t('common.gold')"
          :rules="[rules.required]"
          v-model="task.gold"
        ></v-select>
        <v-select
          v-model="task.tags"
          :items="mappedTags"
          :rules="[rules.maxArrayLength]"
          chips
          :label="$t('tags.tags')"
          item-value="id"
          item-text="name"
          multiple
          solo
          deletable-chips
          class="tags-select"
        >
        </v-select>
        <h3>{{ $t('common.type') }}</h3>
        <v-radio-group v-model="task.type" row>
          <v-radio
            v-for="type of taskTypes"
            :key="type"
            :label="$t(`tasks.taskTypes.${type}`)"
            :value="type"
            color="primary"
          ></v-radio>
        </v-radio-group>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="save()">
        {{ $t('common.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import validators from '../../../core/validators';
import taskTypes from '../../../enums/task.types';

const MIN_GOLD = 100;
const MAX_GOLD = 1000;
const GOLD_INCREMENT = 50;
const MIN_TAGS = 0;
const MAX_TAGS = 3;

export default {
  name: 'manage-task-modal',
  props: {
    selectedTask: Object
  },
  computed: {
    ...mapGetters(['tags']),
    mappedTags() {
      return this.tags.map(tag => ({
        id: tag.id,
        name: this.$i18n.t(`tags.${tag.name}`)
      }));
    },
    gold() {
      let counter = MIN_GOLD;
      const result = [counter];

      while (counter < MAX_GOLD) {
        counter += GOLD_INCREMENT;
        result.push(counter);
      }

      return result;
    }
  },
  data() {
    return {
      taskTypes: Object.values(taskTypes),
      task: {
        title: {
          en: '',
          bg: ''
        },
        notes: {
          en: '',
          bg: ''
        },
        gold: '',
        type: taskTypes.todo,
        tags: []
      },
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField')),
        maxArrayLength: validators.minMaxArrayLength(
          MIN_TAGS,
          MAX_TAGS,
          this.$i18n.t('tasks.maxTagsLength', { number: MAX_TAGS })
        )
      }
    };
  },
  created() {
    if (this.selectedTask) {
      this.task = this.selectedTask;
    }
  },
  methods: {
    async save() {
      if (!this.$refs.manageTaskForm.validate()) {
        return;
      }

      this.$emit('close', this.task);
    }
  }
};
</script>
