<template>
  <fragment>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :loading="loading"
      :loading-text="$t('common.loadingData')"
      :no-data-text="$t('common.noData')"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat color="white" class="mb-4">
          <v-toolbar-title>{{ $t('tasks.tasks') }}</v-toolbar-title>
          <div class="flex-grow-1"></div>
          <v-btn small fab depressed color="primary" @click="createTask()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
        <tasks-list-filter
          :apply-filters="applyFilters"
          :applied-filters="filters"
        ></tasks-list-filter>
      </template>
      <template v-slot:item.title="{ item }">
        {{ item.title[currentUser.lang] | truncate(30) }}
      </template>
      <template v-slot:item.notes="{ item }">
        <span v-if="item.notes[currentUser.lang]">
          {{ item.notes[currentUser.lang] | truncate(30) }}
        </span>
        <span v-else>-</span>
      </template>
      <template v-slot:item.status="{ item }">
        <v-tooltip top :color="getApprovalColor(item.status)">
          <template v-slot:activator="{ on }">
            <v-icon x-small :color="getApprovalColor(item.status)" v-on="on">
              mdi-circle
            </v-icon>
          </template>
          <span>{{ item.status }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.tags="{ item }">
        <v-menu open-on-hover bottom offset-y v-if="item.tags.length > 0">
          <template v-slot:activator="{ on }">
            <v-icon small color="primary" v-on="on" class="ml-2">
              mdi-tag
            </v-icon>
          </template>

          <v-list>
            <v-list-item v-for="tag of item.tags" :key="tag.id">
              <v-list-item-title>
                <v-chip color="primary">{{ tag.name }}</v-chip>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.createdBy="{ item }">
        <span>
          {{ item.createdBy | truncate(15) }}
        </span>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          x-small
          fab
          depressed
          color="primary"
          class="mr-1"
          @click="updateTask(item)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <!-- <v-btn
          x-small
          fab
          depressed
          color="success"
          class="mr-1"
          @click="approve(item.id)"
        >
          <v-icon>mdi-thumbs-up-down</v-icon>
        </v-btn> -->
        <v-btn
          v-if="canDelete"
          x-small
          fab
          depressed
          color="error"
          class="mr-1"
          @click="deleteTask(item.id)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex';
import ManageTaskModal from './ManageTaskModal';
import TasksListFilter from './TasksListFilter';
import ConfirmModal from '../../shared/components/ConfirmModal';
import taskMapper from '../mappers/tasks.mapper';
import approvalStatusesMixin from '../../shared/mixins/approval.statuses.mixin';
import listItemsMixin from '../../shared/mixins/list.items.mixin';
import { GET_TASKS, ADD_TASK, UPDATE_TASK, DELETE_TASK } from '../store/types';
import resources from '../../../enums/resources';
import aclMixin from '../../shared/mixins/acl.mixin';

export default {
  name: 'tasks-list',
  components: {
    TasksListFilter
  },
  computed: {
    ...mapGetters({
      items: 'tasks',
      totalItems: 'totalTasks',
      currentUser: 'currentUser'
    })
  },
  mixins: [aclMixin, listItemsMixin, approvalStatusesMixin],
  data() {
    return {
      loading: true,
      feature: resources.gamTasks,
      headers: [
        {
          text: this.$i18n.t('common.title'),
          value: 'title',
          sortable: false
        },
        {
          text: this.$i18n.t('common.notes'),
          value: 'notes',
          sortable: false
        },
        {
          text: this.$i18n.t('common.gold'),
          value: 'gold',
          sortable: false
        },
        {
          text: this.$i18n.t('common.status'),
          value: 'status',
          sortable: false
        },
        {
          text: this.$i18n.t('common.type'),
          value: 'type',
          sortable: false
        },
        {
          text: this.$i18n.t('tags.tags'),
          value: 'tags',
          sortable: false
        },
        {
          text: this.$i18n.t('common.createdBy'),
          value: 'createdBy',
          sortable: false
        },
        {
          text: this.$i18n.t('common.actions'),
          value: 'action',
          sortable: false
        }
      ]
    };
  },
  methods: {
    async getItems(params) {
      this.loading = true;
      await this.$store.dispatch(GET_TASKS, params);
      this.loading = false;
    },
    async createTask() {
      const payload = await this.$root.$modal.show(ManageTaskModal);

      if (!payload) return;

      await this.$store.dispatch(ADD_TASK, payload);
      this.resetList();

      this.$notify({
        type: 'success',
        text: this.$i18n.t('tasks.createSuccess')
      });
    },
    async updateTask(task) {
      const payload = await this.$root.$modal.show(ManageTaskModal, {
        selectedTask: taskMapper.transformTaskToUpdateModel(task)
      });

      if (!payload) return;

      await this.$store.dispatch(UPDATE_TASK, payload);

      this.$notify({
        type: 'success',
        text: this.$i18n.t('tasks.updateSuccess')
      });
    },
    async deleteTask(id) {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$i18n.t('tasks.deleteConfirmText')
      });

      if (!confirmed) return;

      await this.$store.dispatch(DELETE_TASK, id);

      this.resetList(false);

      this.$notify({
        type: 'success',
        text: this.$i18n.t('tasks.deleteSuccess')
      });
    }
  }
};
</script>
